<template>
  <div class="minePage flex-column-center">
    <tab-nav isActive="mine"></tab-nav>
    <div class="userLine flex-row-center">
      <img
        class="userIcon"
        :src="userInfo.avatar"
        alt=""
        @click="goUserInfo"
      >
      <div class="userInfo">
        <div class="userName">{{userInfo.nickname}}</div>
        <div class="userTitle">{{userInfo.official}}</div>
      </div>
      <div class="coinNum flex-row-center">
        <img
          class="coinIcon"
          src="https://ycmall-oss.oss-cn-hangzhou.aliyuncs.com/common-static/sgs-forum-h5/taskRewardIcon.png"
          alt=""
        >
        <div class="coinNumText">{{userInfo.leaf}}</div>
      </div>
    </div>
    <div class="userDataLine flex-row-center">
      <div
        class="dataItem flex-column-center"
        v-for="(item,index) in userDataList"
        :key="index"
        @click="goPage(index)"
      >
        <div class="userNum">{{item.num}}</div>
        <img
          class="dataTag"
          :src="item.img"
          alt=""
        >
      </div>
    </div>
    <div class="checkInBox flex-column-center">
      <img
        class="dailyCheckIn"
        src="https://ycmall-oss.oss-cn-hangzhou.aliyuncs.com/common-static/sgs-forum-h5/checkIn.png"
        alt=""
      >
      <div
        class="exchangeBtn"
        @click="goExchange"
      >兌換專區 ></div>
      <div class="signTagLine flex-row-center">
        <div
          class="tagBox flex-column-center"
          v-for="(item,index) in dailyRewardList"
          :key="index"
        >
          <div
            class="finishText"
            v-if="item.day <= currentDayNum"
          >已領</div>
          <div
            class="rewardText"
            v-if="item.day > currentDayNum"
          >+{{item.reward}}</div>
          <img
            class="tagImg"
            src="https://ycmall-oss.oss-cn-hangzhou.aliyuncs.com/common-static/sgs-forum-h5/signTag0.png"
            alt=""
            v-if="item.day <= currentDayNum"
          >
          <img
            class="tagImg"
            src="https://ycmall-oss.oss-cn-hangzhou.aliyuncs.com/common-static/sgs-forum-h5/siginTag1.png"
            alt=""
            v-if="item.day > currentDayNum"
          >
        </div>
      </div>
      <div class="progressLine">
        <div
          class="inner"
          :style="`width:${currentDayNum/28*100}%`"
        ></div>
      </div>
      <div class="progressPoint flex-row-center">
        <div
          class="pointBox flex-column-center"
          v-for="(item,index) in dailyRewardList"
          :key="index"
        >
          <div
            class="finishPoint"
            v-if="item.day <= currentDayNum"
          ></div>
          <div
            class="blankPoint"
            v-if="item.day > currentDayNum"
          ></div>
          <div class="dayText">{{item.day}}天</div>
        </div>
      </div>
      <img
        class="checkInBtn"
        src="https://ycmall-oss.oss-cn-hangzhou.aliyuncs.com/common-static/sgs-forum-h5/checkInBtn.png"
        alt=""
        v-if="!signInStatus"
        @click="goCheckIn"
      >
      <div
        class="checkInPop"
        v-if="!signInStatus&& dailyTaskList.length>0"
      >+{{dailyTaskList[currentDayNum].reward_leaf}}</div>
      <img
        class="checkInBtn"
        src="https://ycmall-oss.oss-cn-hangzhou.aliyuncs.com/common-static/sgs-forum-h5/getSign.png"
        alt=""
        v-if="signInStatus"
      >
    </div>
    <div class="dailyTaskBox flex-column-center">
      <img
        class="taskTag"
        src="https://ycmall-oss.oss-cn-hangzhou.aliyuncs.com/common-static/sgs-forum-h5/taskTag.png"
        alt=""
      >
      <div
        class="taskLine flex-row-center"
        v-for="(item,index) in taskList"
        :key="index"
      >
        <img
          class="taskIcon"
          :src="item.img"
          alt=""
        >
        <div class="taskTitle">{{item.name}}</div>
        <div class="taskDesc">{{item.desc}}</div>
        <div class="taskRewardBox flex-row-center">
          <img
            class="taskRewardIcon"
            src="https://ycmall-oss.oss-cn-hangzhou.aliyuncs.com/common-static/sgs-forum-h5/taskRewardIcon.png"
            alt=""
          >
          +{{item.reward_leaf}}
        </div>
        <div
          class="goTaskBtn"
          v-if="!item.finished"
          @click="goPostIndex"
        >去完成</div>
        <div
          class="finishBtn"
          v-if="item.finished"
          @click="goGetReward(item)"
        >未領取</div>
        <div
          class="gotBtn"
          v-if="item.rewarded"
        >已獲得</div>
      </div>
    </div>
    <CheckInModal
      ref="checkInModal"
      @initUser="initTaskInfo"
    ></CheckInModal>
    <div
      class="cover"
      v-if="isShowLogIn"
    ></div>
    <login
      :isLoginFade="isShowLogIn"
      v-on:changeCloseFade="closeLogIn"
    ></login>
    <div
      class="noLoginCover"
      @click="tapNoLoginCover"
      v-if="isShowNoLoginCover"
    ></div>
  </div>
</template>
<script type="text/ecmascript-6">
import CheckInModal from '../../components/checkInModal/CheckInModal.vue'
import tabNav from '../../components/tabNav/tabNav.vue'
import { userInfo, getTaskInfo, getReward } from '../../api/service'
import login from '../../components/login/login.vue'
import { Toast } from 'vant'

export default {
  name: 'Mine',
  components: {
    tabNav,
    CheckInModal,
    login
  },
  data() {
    return {
      userInfo: {
        nickname: '登錄',
        leaf: 0,
        avatar:
          'https://ycmall-oss.oss-cn-hangzhou.aliyuncs.com/common-static/sgs-forum-h5/avator.png'
      },
      userDataList: [
        {
          img: 'https://ycmall-oss.oss-cn-hangzhou.aliyuncs.com/common-static/sgs-forum-h5/tag1.png',
          num: 0
        },
        {
          img: 'https://ycmall-oss.oss-cn-hangzhou.aliyuncs.com/common-static/sgs-forum-h5/tag2.png',
          num: 0
        },
        {
          img: 'https://ycmall-oss.oss-cn-hangzhou.aliyuncs.com/common-static/sgs-forum-h5/tag3.png',
          num: 0
        },
        {
          img: 'https://ycmall-oss.oss-cn-hangzhou.aliyuncs.com/common-static/sgs-forum-h5/tag4.png',
          num: 0
        },
        {
          img: 'https://ycmall-oss.oss-cn-hangzhou.aliyuncs.com/common-static/sgs-forum-h5/tag5.png',
          num: 0
        },
        {
          img: 'https://ycmall-oss.oss-cn-hangzhou.aliyuncs.com/common-static/sgs-forum-h5/tag6.png',
          num: 0
        }
      ],
      currentDayNum: 0,
      dailyRewardList: [
        {
          day: 1,
          reward: 10
        },
        {
          day: 7,
          reward: 10
        },
        {
          day: 14,
          reward: 10
        },
        {
          day: 21,
          reward: 10
        },
        {
          day: 28,
          reward: 10
        }
      ],
      signInStatus: false,
      taskList: [
        {
          img: 'https://ycmall-oss.oss-cn-hangzhou.aliyuncs.com/common-static/sgs-forum-h5/task0.png',
          name: '點讚',
          desc: '點讚任意帖文1次',
          num: '3',
          finished: false,
          rewarded: false
        },
        {
          img: 'https://ycmall-oss.oss-cn-hangzhou.aliyuncs.com/common-static/sgs-forum-h5/task1.png',
          name: '點讚',
          desc: '點讚任意帖文1次',
          num: '3',
          finished: false,
          rewarded: false
        },
        {
          img: 'https://ycmall-oss.oss-cn-hangzhou.aliyuncs.com/common-static/sgs-forum-h5/task2.png',
          name: '點讚',
          desc: '點讚任意帖文1次',
          num: '3',
          finished: false,
          rewarded: false
        },
        {
          img: 'https://ycmall-oss.oss-cn-hangzhou.aliyuncs.com/common-static/sgs-forum-h5/task3.png',
          name: '點讚',
          desc: '點讚任意帖文1次',
          num: '3',
          finished: false,
          rewarded: false
        }
      ],
      dailyTaskList: [],
      isShowLogIn: false,
      isShowNoLoginCover: false
    }
  },
  mounted() {
    this.initData()
  },
  methods: {
    initData() {
      if (!localStorage.token) {
        this.isShowLogIn = true
        this.isShowNoLoginCover = true
      } else {
        this.isShowLogIn = false
        this.isShowNoLoginCover = false
      }
      this.initUserInfo()
      this.initTaskInfo()
    },
    initUserInfo() {
      userInfo().then((res) => {
        if (res.code == 0) {
          this.userInfo = res.data
          this.userDataList[0].num = res.data.viewed_cnt
          this.userDataList[1].num = res.data.liked_cnt
          this.userDataList[2].num = res.data.replied_cnt
          this.userDataList[3].num = res.data.reply_cnt
          this.userDataList[4].num = res.data.fan_cnt
          this.userDataList[5].num = res.data.follow_cnt
        }
      })
    },
    initTaskInfo() {
      getTaskInfo().then((res) => {
        if (res.code == 0) {
          // 每日任务
          this.dailyTaskList = res.data.daily_sign_in.tasks
          this.dailyRewardList[0].reward =
            res.data.daily_sign_in.tasks[0].reward_leaf
          this.dailyRewardList[1].reward =
            res.data.daily_sign_in.tasks[6].reward_leaf
          this.dailyRewardList[2].reward =
            res.data.daily_sign_in.tasks[13].reward_leaf
          this.dailyRewardList[3].reward =
            res.data.daily_sign_in.tasks[20].reward_leaf
          this.dailyRewardList[4].reward =
            res.data.daily_sign_in.tasks[27].reward_leaf
          // this.currentDayNum = res.data.daily_sign_in.today_task_idx + 1
          this.currentDayNum =res.data.daily_sign_in.tasks[res.data.daily_sign_in.today_task_idx].done_num

          // if (!localStorage.token) {
          //   this.currentDayNum = 0
          // }
          this.signInStatus =
            this.dailyTaskList[res.data.daily_sign_in.today_task_idx].finished

          // 底部任务
          res.data.daily_routine.tasks.map((item, index) => {
            this.taskList[index].name = item.name
            this.taskList[index].desc = item.desc
            this.taskList[index].reward_leaf = item.reward_leaf
            this.taskList[index].finished = item.finished
            this.taskList[index].rewarded = item.rewarded
            this.taskList[index].id = item.id
          })
        }
      })
    },
    goCheckIn() {
      this.$refs.checkInModal.showModal(
        this.dailyTaskList[this.currentDayNum].reward_leaf
      )
    },
    goExchange() {
      this.$router.push('exchange')
    },
    goUserInfo() {
      this.$router.push({
        path: 'Author',
        query: {
          userId: localStorage.userId
        }
      })
    },
    goPage(index) {
      switch (index) {
        case 0:
        case 1:
        case 2:
          this.goUserInfo()
          break
        case 3:
          this.$router.push({
            path: 'Message'
          })
          break
        case 4:
          this.$router.push({
            path: 'Fans',
            query: {
              fansNum: this.userDataList[4].num,
              userId: localStorage.userId
            }
          })
          break
        case 5:
          this.$router.push({
            path: 'Focus',
            query: {
              focusNum: this.userDataList[5].num,
              userId: localStorage.userId
            }
          })
          break

        default:
          break
      }
    },
    goPostIndex() {
      this.$router.push({
        path: 'postIndex'
      })
    },
    closeLogIn() {
      this.initData()
      this.isShowLogIn = false
      // this.isShowNoLoginCover = false
    },
    tapNoLoginCover() {
      this.isShowLogIn = true
    },
    goGetReward(item) {
      getReward(item.id).then((res) => {
        if (res.code == 0) {
          Toast('領取成功')
          this.initTaskInfo()
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
@import './Mine.scss';
</style>