<template>
  <div
    class="login-fade"
    v-if="isLoginFade"
  >
    <a
      href="javascript:;"
      class="icon close-btn"
      @click="changeCloseFade"
    ></a>
    <form class="form-box">
      <div class="input-box flex">
        <label>帳號：</label>
        <input
          type="text"
          v-model="username"
        >
        <!-- <a href="" class="icon register-btn"></a> -->
      </div>
      <div class="input-box flex">
        <label>密碼：</label>
        <input
          :type="isHidePas?'password':'text'"
          v-model="password"
        >
        <span
          class="eyes-box"
          @click="changeEyes()"
        >
          <i
            class="icon eyes-icon"
            :class="isHidePas?'':'eyes-status2'"
          ></i>
        </span>
      </div>
      <a
        href="javascript:;"
        @click="changeLogin"
        class="icon login-btn"
      ></a>
    </form>
    <div class="other-box flex">
      <a
        href="javascript:;"
        class="icon face-btn"
        @click="goFacebook()"
      ></a>
      <a
        href="javascript:;"
        class="icon ios-btn"
        @click="goIosLink()"
      ></a>
    </div>

  </div>
</template>
<script>
// import "../login.css";
export default {
  name: 'login',
  inject: ['reload'], // 注入
  data() {
    return {
      isHidePas: true,
      username: '',
      password: '',
      token: localStorage.getItem('token') || '',
      changele: localStorage.getItem('changele') || '',
      code: this.$route.query.code,
      state: this.$route.query.state
    }
  },
  props: {
    isLoginFade: {
      type: Boolean,
      default: false
    }
  },
  created: function () {
    const url = location.href
    console.log(this.queryURLParams(url))
    const paramObj = this.queryURLParams(url)
    if (JSON.stringify(paramObj) != '{}') {
      this.code = paramObj.code
      this.state = paramObj.state
    }

    let _this = this
    if (_this.token == '' && (_this.code || _this.state)) {
      if (_this.changele == 'apple') {
        _this.getAppleValidate()
      } else {
        _this.getFaceValidate()
      }
    }
  },
  methods: {
    queryURLParams(URL) {
      //   let pattern = /(\w+)=(\w+)/gi
      //   let parames = {}
      //   url.replace(pattern, ($, $1, $2) => {
      //     parames[$1] = $2
      //   })
      //   return parames
      let base = URL.split('#')[0]
      let url = base.split('?')[1]
      let obj = {} // 声明参数对象
      let arr = url.split('&') // 以&符号分割为数组
      for (let i = 0; i < arr.length; i++) {
        let arrNew = arr[i].split('=') // 以"="分割为数组
        obj[arrNew[0]] = arrNew[1]
      }
      return obj
    },
    changeEyes() {
      this.isHidePas = !this.isHidePas
    },
    changeCloseFade() {
      this.$emit('changeCloseFade')
    },
    changeLogin() {
      var _this = this
      var itemdata = {
        username: _this.username,
        password: _this.password
      }
      if (_this.username == '') {
        this.$toast.center('帳號不能為空~')
      } else if (_this.password == '') {
        this.$toast.center('密碼不能為空')
      } else {
        this.$http.post('/auth/login', itemdata, (res) => {
          if (res.code == 0) {
            _this.token = res.data.token
            localStorage.setItem('token', res.data.token)
            this.reload()
            this.$emit('changeCloseFade')
          } else {
            _this.$toast.center(res.msg)
          }
        })
      }
    },
    goFacebook: function () {
      this.$http.get('/auth/o/facebook', {}, (res) => {
        if (res.code == 0) {
          localStorage.setItem('changele', 'facebook')
          window.location.href = res.data.url
        } else {
          this.$toast.center(res.msg)
        }
      })
    },
    goIosLink: function () {
      this.$http.get('/auth/o/apple', {}, (res) => {
        if (res.code == 0) {
          localStorage.setItem('changele', 'apple')
          window.location.href = res.data.url
        } else {
          this.$toast.center(res.msg)
        }
      })
    },
    getAppleValidate: function () {
      let _this = this
      let data = {
        code: this.code,
        state: this.state
      }
      this.$http.get('/auth/o/apple/validate', data, (res) => {
        if (res.code == 0) {
          _this.token = res.data.token
          _this.isLoginFade = false
          localStorage.setItem('token', res.data.token)
          localStorage.removeItem('apple')
        } else {
          this.$toast.center(res.msg)
        }
      })
    },
    getFaceValidate: function () {
      let _this = this
      let data = {
        code: this.code,
        state: this.state
      }
      this.$http.get('/auth/o/facebook/validate', data, (res) => {
        if (res.code == 0) {
          _this.token = res.data.token
          _this.isLoginFade = false
          localStorage.setItem('token', res.data.token)
          localStorage.removeItem('apple')
        } else {
          this.$toast.center(res.msg)
        }
      })
    }
  }
}
</script>
<style>
.login-fade {
  width: 8.813333rem;
  height: 8.653333rem;
  background: url(../../assets/login-bg.png) 0 0 no-repeat;
  background-size: 8.813333rem;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 300;
}
.form-box {
  width: 6.1rem;
  margin: 2.2rem auto 1.15rem;
}
.form-box .input-box {
  justify-content: space-between;
  margin-bottom: 0.5067rem;
  font-size: 0.373333rem;
  color: #be6a49;
  position: relative;
}
.register-btn {
  width: 1.293333rem;
  height: 0.72rem;
  background-position: -5.613333rem -4.613333rem;
}
.input-box label {
  font-size: 0.426667rem;
  line-height: 0.72rem;
  color: #e14d39;
}
.input-box select {
  width: 4.76rem;
  height: 0.72rem;
  box-shadow: inset 0rem 0 0.053333rem 0 rgba(189, 154, 93, 0.36);
  background: url(../../assets/select.png) #ebc98e right center no-repeat;
  background-size: 0.6533rem;
  padding-left: 0.2rem;
}
.input-box input {
  width: 4.76rem;
  height: 0.72rem;
  background-color: #ebc98e;
  box-shadow: inset 0 0 0.053333rem 0 rgba(189, 154, 93, 0.36);
  padding-left: 0.2rem;
  font-size: 0.373333rem;
  color: #be6a49;
}

.login-btn {
  width: 4.386667rem;
  height: 1.013333rem;
  display: block;
  margin: 0 auto;
  background-position: -0.133333rem -8.0133rem;
}
.other-box {
  justify-content: center;
}
.face-btn,
.ios-btn {
  width: 1.22rem;
  height: 1.22rem;
}
.face-btn {
  background-position: -4.92rem -7.92rem;
  margin-right: 1.0267rem;
}
.ios-btn {
  background-position: -6.39rem -7.92rem;
}
.eyes-box {
  width: 0.72rem;
  height: 0.72rem;
  background-color: #dab473;
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.eyes-icon {
  width: 0.426667rem;
  height: 0.333333rem;
  background-position: -7.7067rem -3.8667rem;
}
.eyes-status2 {
  background-position: -7.76rem -4.44rem;
}

.close-btn {
  width: 0.586667rem;
  height: 0.586667rem;
  background-position: -7.62rem -2.92rem;
  position: absolute;
  right: 0.05rem;
  top: 0.05rem;
}
</style>
