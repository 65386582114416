<template>
  <div class="nav">
    <ul class="nav-tab flex">
      <li :class="[isActive == 'postIndex' ? 'active' : 'normal']">
        <router-link
          class="link flex"
          :to="{name:'postIndex'}"
        ><i class="icon postIndex"></i></router-link>
      </li>
      <li :class="[isActive == 'ticket' ? 'active' : 'normal']">
        <router-link
          class="link flex"
          :to="{name:'ticket'}"
        ><i class="icon tiket-btn"></i></router-link>
      </li>
      <li :class="[isActive == 'strategy' ? 'active' : 'normal']">
        <router-link
          class="link flex"
          :to="{name:'strategy'}"
        ><i class="icon intro-btn">
          </i>
        </router-link>
      </li>
      <li :class="[isActive == 'mall' ? 'active' : 'normal']">
        <router-link
          class="link flex"
          :to="{name:'mall'}"
        ><i class="icon gold-btn"></i></router-link>
      </li>
      <li :class="[isActive == 'mine' ? 'active' : 'normal']">
        <router-link
          class="link flex"
          :to="{name:'mine'}"
        ><i class="icon mine"></i></router-link>
      </li>
    </ul>

  </div>
</template>

<script>
export default {
  name: 'tabNav',
  props: {
    isActive: {
      //用于外部控制组件的显示/隐藏
      type: String,
      default: 'index'
    }
  }
}
</script>

<style>
.nav {
  width: 100%;
  height: 1.3rem;
  background: url(https://ycmall-oss.oss-cn-hangzhou.aliyuncs.com/common-static/sgs-forum-h5/tabNavBg.png) 0 0 repeat;
  /* background-size: 1.5333rem; */
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 99;
}
.nav-tab li {
  width: 2rem;
  height: 1.3rem;
}
.link {
  width: 2rem;
  height: 1.3rem;
  justify-content: center;
  padding-top: 0.2rem;
}
.nav-tab li.normal {
  width: 2rem;
  height: 2.2rem;
  background: url(../../assets/active_bg.png) 0 0 no-repeat;
  background-size: 2rem;
}
/* .nav-tab li.active {
  width: 2rem;
  height: 2.2rem;
  background: url(../../assets/active_bg.png) 0 0 no-repeat;
  background-size: 2rem;
} */
.postIndex {
  width: 0.93rem;
  height: 0.9rem;
  background-image: url(https://ycmall-oss.oss-cn-hangzhou.aliyuncs.com/common-static/sgs-forum-h5/postIndexNormal.png);
  background-size: cover;
}
.active .postIndex {
  width: 0.93rem;
  height: 1rem;
  background-image: url(https://ycmall-oss.oss-cn-hangzhou.aliyuncs.com/common-static/sgs-forum-h5/postIndexActive.png);
  background-size: cover;
}
.mine {
  width: 0.93rem;
  height: 0.9rem;
  background-image: url(https://ycmall-oss.oss-cn-hangzhou.aliyuncs.com/common-static/sgs-forum-h5/mineDark.png);
  background-size: cover;
}
.active .mine {
  width: 0.93rem;
  height: 1rem;
  background-image: url(https://ycmall-oss.oss-cn-hangzhou.aliyuncs.com/common-static/sgs-forum-h5/mineLight.png);
  background-size: cover;
}
.tiket-btn {
  width: 0.67rem;
  height: 0.89rem;
  background-position: -0.13rem -5.97rem;
}
.active .tiket-btn {
  width: 0.93rem;
  height: 1rem;
  background-position: -5.83rem -3.41rem;
}
.intro-btn {
  width: 0.59rem;
  height: 0.91rem;
  background-position: -7.6rem -0.13rem;
}
.active .intro-btn {
  width: 0.96rem;
  height: 0.93rem;
  background-position: -0.13rem -4.77rem;
}
.gold-btn {
  width: 0.72rem;
  height: 0.87rem;
  background-position: -1.36rem -4.77rem;
}
.active .gold-btn {
  width: 0.96rem;
  height: 0.97rem;
  background-position: -5.83rem -2.17rem;
}
</style>
