<template>
  <div v-show="isShow">
    <BaseModal>
      <div class="checkInModal flex-column-center">
        <img
          class="titleImg"
          src="https://ycmall-oss.oss-cn-hangzhou.aliyuncs.com/common-static/sgs-forum-h5/taskModalTitle.png"
          alt=""
        >
        <div class="rewardBox">+{{num}}</div>
        <img
          class="rewardIcon"
          src="https://ycmall-oss.oss-cn-hangzhou.aliyuncs.com/common-static/sgs-forum-h5/rewardModalIcon.png"
          alt=""
        >
        <img
          class="getBtn"
          src="https://ycmall-oss.oss-cn-hangzhou.aliyuncs.com/common-static/sgs-forum-h5/modalGetIcon.png"
          alt=""
          @click="goGet"
        >
      </div>
    </BaseModal>
  </div>
</template>
<script type="text/ecmascript-6">
import BaseModal from '../baseModal/BaseModal.vue'
import { goSignIn } from '../../api/service'
import { Toast } from 'vant'
export default {
  name: 'CheckInModal',
  components: { BaseModal },
  data() {
    return {
      isShow: false,
      num: 0
    }
  },
  methods: {
    goGet() {
      goSignIn({}).then((res) => {
        if (res.code == 0) {
          Toast('签到成功')
          this.isShow = false
          this.$emit('initUser')
        }
      })
    },
    showModal(num) {
      this.isShow = true
      this.num = num
    }
  }
}
</script>
<style lang="scss" scoped>
@import './CheckInModal.scss';
</style>